import React from 'react';
import Contact from '../../Contact'

const FooterContact = () => {
  return(
    <div className="l-footer-contact">
      <Contact />
    </div>
  )
}

export default FooterContact;